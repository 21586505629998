import { CourseTypeEnum } from 'api/generated';
import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  Create,
  FormTab,
  maxLength,
  minLength,
  NumberInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const CourseCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.COURSES, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.COURSES}`);
    },
  });

  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId } = urlVariables || {};

  const handleSubmit = (data: FieldValues) => {
    create(Resources.COURSES, {
      data: {
        ...data,
        directionId,
      },
    });
  };

  return (
    <Create>
      <TabbedForm onSubmit={handleSubmit}>
        <FormTab label="general">
          <SelectInput
            label="Course Type"
            source="type"
            choices={[
              { id: CourseTypeEnum.Default, name: 'Default' },
              { id: CourseTypeEnum.Certificate, name: 'Certificate' },
            ]}
            validate={required()}
            fullWidth
          />
          <TextInput
            validate={[maxLength(500), minLength(2), validator.textRequired()]}
            source="name"
            fullWidth
            multiline
            isRequired
          />
          <TextInput
            validate={[
              maxLength(1000),
              minLength(2),
              validator.text,
              validator.textRequired(),
            ]}
            source="description"
            fullWidth
            multiline
            isRequired
          />
          <NumberInput
            validate={required()}
            source="assignmentsWeight"
            fullWidth
          />
          <NumberInput
            validate={required()}
            source="flashcardsWeight"
            fullWidth
          />
          <NumberInput
            validate={required()}
            source="testTopicsWeight"
            fullWidth
          />
          <TextInput
            validate={[maxLength(15), minLength(2), validator.textRequired()]}
            source="domainButtonName"
            label="Domain button name"
            fullWidth
            isRequired
          />
          <TextInput
            validate={[maxLength(15), minLength(2), validator.textRequired()]}
            source="chapterButtonName"
            label="Chapter button name"
            fullWidth
            isRequired
          />
          <TextInput
            validate={[maxLength(15), minLength(2), validator.textRequired()]}
            source="domainStatsButtonName"
            label="Stats domain button name"
            fullWidth
            isRequired
          />
          <TextInput
            validate={[maxLength(15), minLength(2), validator.textRequired()]}
            source="chapterStatsButtonName"
            label="Stats chapter button name"
            fullWidth
            isRequired
          />
          <NumberInput source="activeCampaignListId" fullWidth multiline />
          <SelectInput
            label="Study this category"
            source="leastKnownCategory"
            choices={[
              { id: 'chapter', name: 'Chapter' },
              { id: 'domain', name: 'Domain' },
            ]}
            validate={required()}
            fullWidth
          />
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CourseCreate;
