import { CouponResponseDtoSubscriptionDurationTypeEnum } from 'api/generated';
import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  AutocompleteArrayInput,
  Create,
  DateInput,
  FormDataConsumer,
  FormTab,
  maxLength,
  minLength,
  minValue,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';
import { Grid } from '@mui/material';
import { getRedeemBy } from './utils';
import {
  allowedPeriodsOptions,
  allowedSubscriptionPlansOptions,
  discountTypeOptions,
  expireDateOptions,
  limitUsesOptions,
  subscriptionDurationTypeOptions,
} from './constants';
import { setStringArray } from 'utils/setStringArray';

const CouponsCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.COUPONS, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.COUPONS}`);
    },
  });

  const { urlVariables } = useContext(NestedResourcesContext);
  const { career: directionId } = urlVariables || {};

  const handleSubmit = (data: FieldValues) => {
    create(Resources.COUPONS, {
      data: {
        name: data.name,
        directionId,
        promoCode: data.promoCode,
        discountType: data.discountType,
        discountAmount: data.discountAmount,
        redeemBy: getRedeemBy(data.expireDateOptions, data.expireDateCustom),
        subscriptionDurationType: data.subscriptionDurationType,
        subscriptionDuration: data.subscriptionDuration,
        maxRedemptions:
          data.maxRedemptionsOptions === 'limit' ? data.maxRedemptions : null,
        allowedPeriods: [data.allowedPeriods],
        allowedPlans: [data.allowedSubscriptionPlans],
        allowedCoursesIds: data.allowedCoursesIds,
      },
    });
  };

  return (
    <Create>
      <TabbedForm onSubmit={handleSubmit}>
        <FormTab label="general">
          <TextInput
            validate={[maxLength(150), minLength(2), validator.textRequired()]}
            source="name"
            fullWidth
            multiline
            isRequired
          />
          <TextInput
            validate={[
              maxLength(150),
              minLength(2),
              validator.textRequired(),
              validator.capitalText,
            ]}
            source="promoCode"
            fullWidth
            multiline
            isRequired
          />
          <ReferenceArrayInput
            source="allowedCoursesIds"
            reference={Resources.COURSES}
            perPage={100}
          >
            <AutocompleteArrayInput
              label="Select course"
              optionText="name"
              optionValue="id"
              format={setStringArray}
              filterToQuery={(value: string) => ({ name: value })}
              filterSelectedOptions
              validate={required()}
              fullWidth
            />
          </ReferenceArrayInput>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="allowedSubscriptionPlans"
                choices={allowedSubscriptionPlansOptions}
                validate={required()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="allowedPeriods"
                choices={allowedPeriodsOptions}
                validate={required()}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="discountType"
                choices={discountTypeOptions}
                validate={required()}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <NumberInput source="discountAmount" isRequired fullWidth />
            </Grid>
          </Grid>
          <RadioButtonGroupInput
            label="Limit uses"
            source="maxRedemptionsOptions"
            choices={limitUsesOptions}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.maxRedemptionsOptions === 'limit' && (
                <NumberInput
                  source="maxRedemptions"
                  {...rest}
                  label="Limit to"
                />
              )
            }
          </FormDataConsumer>
          <RadioButtonGroupInput
            source="expireDateOptions"
            choices={expireDateOptions}
          />
          <FormDataConsumer>
            {({ formData, ...rest }) =>
              formData.expireDateOptions === 'custom' && (
                <Grid container spacing={{ xs: 2, md: 3 }}>
                  <Grid item xs={12} lg={3}>
                    <DateInput
                      source="expireDateCustom"
                      validate={minValue(new Date())}
                      label="To"
                      fullWidth
                      {...rest}
                    />
                  </Grid>
                </Grid>
              )
            }
          </FormDataConsumer>
          <Grid container spacing={{ xs: 2, md: 3 }}>
            <Grid item xs={12} lg={3}>
              <SelectInput
                source="subscriptionDurationType"
                choices={subscriptionDurationTypeOptions}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3}>
              <FormDataConsumer>
                {({ formData, ...rest }) =>
                  formData.subscriptionDurationType ===
                    CouponResponseDtoSubscriptionDurationTypeEnum.Repeating && (
                    <NumberInput source="subscriptionDuration" {...rest} />
                  )
                }
              </FormDataConsumer>
            </Grid>
          </Grid>
        </FormTab>
      </TabbedForm>
    </Create>
  );
};

export default CouponsCreate;
