import api from 'api';
import { Accordion, ImportButton } from 'components';
import React, { Dispatch, FC, useState } from 'react';
import { Button, CreateButton, TopToolbar } from 'react-admin';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/Delete';

import styled from 'styled-components';
import { Guide } from 'api/generated';
import { useAppNotify } from 'hooks';

type ActionsProps = {
  guideId: string;
  courseId: string;
  cheatSheetUrl: string;
  textbookUrl: string;
  createResource?: string;
  setStudyGuide: Dispatch<React.SetStateAction<Guide | null>>;
};

enum AccordionEnum {
  CHEATSHEET = 'cheatsheet',
  TEXT_BOOK = 'textBook',
}

const Actions: FC<ActionsProps> = ({
  guideId,
  courseId,
  cheatSheetUrl,
  textbookUrl,
  createResource,
  setStudyGuide,
}) => {
  const { successNotify } = useAppNotify();

  // const onUploadCheatSheetToStorage = async (cheatSheetUrl: string) => {
  //   await api.AdminPanelGuides.adminGuidesControllerAddOrChangeCheatSheet(
  //     guideId || '',
  //     { cheatSheetUrl },
  //   );

  //   setStudyGuide((guide) => (guide ? { ...guide, cheatSheetUrl } : null));

  //   successNotify('Cheatsheet uploaded successfully');
  // };

  // const deleteCheatSheet = async () => {
  //   await api.AdminPanelGuides.adminGuidesControllerAddOrChangeCheatSheet(
  //     guideId || '',
  //     { cheatSheetUrl: null },
  //   );

  //   setStudyGuide((guide) =>
  //     guide ? { ...guide, cheatSheetUrl: null } : null,
  //   );

  //   successNotify('Cheatsheet deleted successfully');
  // };

  const deleteTextBook = async () => {
    await api.AdminPanelGuides.adminGuidesControllerAddOrChangeTextBook(
      guideId || '',
      { textbookUrl: null },
    );

    setStudyGuide((guide) => (guide ? { ...guide, textbookUrl: null } : null));

    successNotify('Text book deleted successfully');
  };

  const onUploadTextBookToStorage = async (textbookUrl: string) => {
    await api.AdminPanelGuides.adminGuidesControllerAddOrChangeTextBook(
      guideId || '',
      { textbookUrl },
    );

    setStudyGuide((guide) => (guide ? { ...guide, textbookUrl } : null));

    successNotify('Text book uploaded successfully');
  };

  const [expanded, setExpanded] = useState<string | null>(null);

  const handleChange = (panel: AccordionEnum, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : null);
  };

  return (
    <Root>
      <TopToolbar>
        <CreateButton resource={createResource} />
        <Wrapper>
          <Accordion
            title="Cheatsheet"
            isOpen={expanded === AccordionEnum.CHEATSHEET}
            onClick={() =>
              handleChange(
                AccordionEnum.CHEATSHEET,
                expanded !== AccordionEnum.CHEATSHEET,
              )
            }
          >
            <Wrapper>
              {cheatSheetUrl && (
                <A target="_blank" rel="noreferrer" href={cheatSheetUrl}>
                  <Button size="small" label="Download">
                    <FileDownloadIcon />
                  </Button>
                </A>
              )}
              {/* <ImportButton
                id="cheat-sheet-input"
                text="Upload"
                accept="application/pdf"
                uploadFileToStorage={async ({ filename }) =>
                  await api.AdminPanelGuides.adminGuidesControllerUploadCheatSheet(
                    {
                      courseId,
                      filename,
                    },
                  )
                }
                onUploadFileToStorage={onUploadCheatSheetToStorage}
              /> */}
              {/* {cheatSheetUrl && (
                <Button
                  style={{ justifyContent: 'flex-start' }}
                  onClick={deleteCheatSheet}
                  size="small"
                  label="Delete"
                >
                  <DeleteIcon />
                </Button>
              )} */}
            </Wrapper>
          </Accordion>
          <Accordion
            title="Text book"
            isOpen={expanded === AccordionEnum.TEXT_BOOK}
            onClick={() =>
              handleChange(
                AccordionEnum.TEXT_BOOK,
                expanded !== AccordionEnum.TEXT_BOOK,
              )
            }
          >
            <Wrapper>
              {textbookUrl && (
                <A target="_blank" rel="noreferrer" href={textbookUrl}>
                  <Button size="small" label="Download">
                    <FileDownloadIcon />
                  </Button>
                </A>
              )}
              <ImportButton
                id="text-book-input"
                text="Upload"
                accept="application/pdf"
                uploadFileToStorage={async ({ filename }) =>
                  await api.AdminPanelGuides.adminGuidesControllerUploadTextbook(
                    {
                      courseId,
                      filename,
                    },
                  )
                }
                onUploadFileToStorage={onUploadTextBookToStorage}
              />
              {textbookUrl && (
                <Button
                  style={{ justifyContent: 'flex-start' }}
                  onClick={deleteTextBook}
                  size="small"
                  label="Delete"
                >
                  <DeleteIcon />
                </Button>
              )}
            </Wrapper>
          </Accordion>
        </Wrapper>
      </TopToolbar>
    </Root>
  );
};

export default Actions;

const Root = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;

  gap: 4px;
`;

const A = styled.a`
  text-decoration: none;
  font-size: inherit;

  svg {
    width: 18px !important;
    height: 18px !important;
  }

  button {
    white-space: nowrap;
    padding: 4px 5px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  gap: 16px;
`;
