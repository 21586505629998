import { NestedResourcesContext } from 'context/NestedResources';
import { useOnFetchError } from 'hooks';
import { useContext } from 'react';
import {
  Create,
  required,
  SelectInput,
  SimpleForm,
  TextInput,
  useCreate,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Resources } from 'types';
import { validator } from 'utils/validator';

const InviteCreate = () => {
  const navigate = useNavigate();
  const { onError } = useOnFetchError();
  const [create] = useCreate(Resources.INVITES, undefined, {
    onError,
    onSuccess() {
      navigate(`/${Resources.INVITES}`);
    },
  });
  const { urlVariables } = useContext(NestedResourcesContext);
  const { course } = urlVariables || {};

  const createInvite = async (data: FieldValues) => {
    await create(Resources.INVITES, {
      data: { ...data, courseId: course },
    });
  };

  return (
    <Create>
      <SimpleForm onSubmit={createInvite}>
        <TextInput
          label="email"
          source="customerEmail"
          fullWidth
          validate={[required(), validator.email]}
        />
        <SelectInput
          source="billingType"
          label="Plan level"
          validate={[required()]}
          choices={[
            { id: 'bronze', name: 'Rookie' },
            { id: 'silver', name: 'All-star' },
            { id: 'gold', name: 'MVP' },
          ]}
        />
      </SimpleForm>
    </Create>
  );
};

export default InviteCreate;
