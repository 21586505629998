import { Switch } from '@mui/material';
import { FC, useEffect } from 'react';

import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import Inputs from './components/Inputs';

type PurchaseInputsProps = {
  isActive: boolean;
  isEdit?: boolean;
  purchaseTerm: string;
  setIsActive: (value: boolean) => void;
};

const PurchaseInputs: FC<PurchaseInputsProps> = ({
  isActive,
  isEdit = false,
  purchaseTerm,
  setIsActive,
}) => {
  const context = useFormContext();
  // console.log('context => ', context.getValues());
  const isPrices =
    !!context.getValues()?.prices?.[purchaseTerm]?.bronze?.amount;

  useEffect(() => {
    if (isPrices && isEdit && !isActive) {
      setIsActive(true);
    }
  }, [isPrices]);

  const enable = () => setIsActive(true);
  const disable = () => setIsActive(false);

  return (
    <>
      <Switch
        checked={isActive}
        defaultValue=""
        onChange={isActive ? disable : enable}
        inputProps={{ 'aria-label': 'controlled' }}
      />
      <Container>
        <h3>Bronze</h3>
        <Inputs
          isActive={isActive}
          subscriptionsTerm={purchaseTerm}
          type="bronze"
        />
        <h3>Silver</h3>
        <Inputs
          isActive={isActive}
          subscriptionsTerm={purchaseTerm}
          type="silver"
        />
        <h3>Gold</h3>
        <Inputs
          isActive={isActive}
          subscriptionsTerm={purchaseTerm}
          type="gold"
        />
      </Container>
    </>
  );
};

export default PurchaseInputs;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  width: 100%;
`;
