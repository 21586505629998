import { CourseDtoTypeEnum } from 'api/generated';
import {
  CourseActions,
  PurchaseFields,
  SubscriptionsFields,
  InstallmentFields,
} from 'components';
// import { useQuery } from 'hooks';
import {
  DateField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
  useShowController,
} from 'react-admin';

const CourseShow = () => {
  const { record } = useShowController({ resource: 'admin/course' });

  const isCertificate = record?.type === CourseDtoTypeEnum.Certificate;

  return (
    <Show title="Course" actions={<CourseActions />}>
      <TabbedShowLayout>
        <Tab label="About course">
          <TextField source="id" />
          <TextField source="name" />
          <TextField source="description" />
          <TextField source="leastKnownCategory" />
          <TextField source="assignmentsWeight" />
          <TextField source="flashcardsWeight" />
          <TextField source="testTopicsWeight" />
          <TextField source="domainButtonName" label="Domain button name" />
          <TextField
            source="domainStatsButtonName"
            label="Domain status button name"
          />
          <TextField source="chapterButtonName" label="Chapter button name" />
          <TextField
            source="chapterStatsButtonName"
            label="Chapter status button name"
          />
          <DateField source="releasedAt" />
          <DateField source="createdAt" />
          <TextField source="activeCampaignListId" />
        </Tab>
        {isCertificate && (
          <Tab label="Installment">
            <InstallmentFields subscriptionsTerm="installment" />
          </Tab>
        )}
        <Tab label="purchase">
          <PurchaseFields subscriptionsTerm="purchase" />
        </Tab>
        {!isCertificate && (
          <Tab label="Monthly subscriptions">
            <SubscriptionsFields subscriptionsTerm="monthlySubscriptions" />
          </Tab>
        )}
        {!isCertificate && (
          <Tab label="Yearly subscriptions">
            <SubscriptionsFields subscriptionsTerm="annualSubscriptions" />
          </Tab>
        )}
      </TabbedShowLayout>
    </Show>
  );
};

export default CourseShow;
