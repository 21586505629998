import api from 'api';
import { PurchaseInputs, SubscriptionsInputs } from 'components';
import { useOnFetchError } from 'hooks';
import { useState } from 'react';
import {
  FormTab,
  maxLength,
  minLength,
  NumberInput,
  required,
  SelectInput,
  TabbedForm,
  TextInput,
  useRecordContext,
} from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorResponse, Resources } from 'types';
import { validator } from 'utils/validator';
import CourseEditToolbar from '../CourseEditToolbar';
import { CourseTypeEnum } from 'api/generated';
import InstallmentInputs from 'components/InstallmentInputs';

const SuperAdminForm = () => {
  const [hasPurchase, setHasPurchase] = useState(false);
  const [hasInstallment, setHasInstallment] = useState(false);
  const [hasMonthlySubscriptions, setHasMonthlySubscriptions] = useState(false);
  const [hasYearlySubscriptions, setHasYearlySubscriptions] = useState(false);

  const navigate = useNavigate();
  const { onError } = useOnFetchError();

  const { prices, type } = useRecordContext();

  const hasPrices = Boolean(prices);
  const isCertificate = type === CourseTypeEnum.Certificate;

  const postSave = async (data: FieldValues) => {
    const processPrices = (prices: any) => {
      Object.keys(prices).forEach((key) => {
        if (prices[key] && typeof prices[key] === 'object') {
          prices[key].oldAmount = null;
        }
      });
      return prices;
    };

    const monthlySubscriptions = hasMonthlySubscriptions
      ? processPrices(data.prices.monthlySubscriptions)
      : undefined;

    const annualSubscriptions = hasYearlySubscriptions
      ? processPrices(data.prices.annualSubscriptions)
      : undefined;

    const purchase = hasPurchase
      ? processPrices(data.prices.purchase)
      : undefined;

    const installment = hasInstallment
      ? processPrices(data.prices.installment)
      : undefined;
    const prices = {
      monthlySubscriptions,
      annualSubscriptions,
      purchase,
      installment,
    };

    try {
      await api.AdminPanelCourses.adminCoursesControllerUpdateCourse(data.id, {
        ...data,
      });

      if (hasPrices) {
        await api.AdminPanelCourses.adminCoursesControllerUpdatePrices(
          data.id,
          prices,
        );
      } else {
        await api.AdminPanelCourses.adminCoursesControllerCreatePrices(
          data.id,
          prices,
        );
      }

      navigate(`/${Resources.COURSES}/${data.id}/show`);
    } catch (error) {
      onError(error as ErrorResponse);
    }
  };

  return (
    <TabbedForm toolbar={<CourseEditToolbar />} onSubmit={postSave}>
      <FormTab label="general">
        <TextInput
          validate={[maxLength(150), minLength(2), validator.textRequired()]}
          source="name"
          fullWidth
          multiline
          isRequired
        />
        <TextInput
          validate={[
            maxLength(1000),
            minLength(2),
            validator.text,
            validator.textRequired(),
          ]}
          source="description"
          fullWidth
          multiline
          isRequired
        />
        <NumberInput
          validate={required()}
          source="assignmentsWeight"
          fullWidth
        />
        <NumberInput
          validate={required()}
          source="flashcardsWeight"
          fullWidth
        />
        <NumberInput
          validate={required()}
          source="testTopicsWeight"
          fullWidth
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="domainButtonName"
          label="Domain button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="chapterButtonName"
          label="Chapter button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="domainStatsButtonName"
          label="Stats domain button name"
          fullWidth
          isRequired
        />
        <TextInput
          validate={[maxLength(15), minLength(2), validator.textRequired()]}
          source="chapterStatsButtonName"
          label="Stats chapter button name"
          fullWidth
          isRequired
        />
        <NumberInput source="activeCampaignListId" fullWidth multiline />
        <SelectInput
          label="Study this category"
          source="leastKnownCategory"
          choices={[
            { id: 'chapter', name: 'Chapter' },
            { id: 'domain', name: 'Domain' },
          ]}
          validate={required()}
          fullWidth
        />
      </FormTab>
      {isCertificate && (
        <FormTab label="Installment">
          <InstallmentInputs
            isActive={hasInstallment}
            setIsActive={setHasInstallment}
            purchaseTerm="installment"
            isEdit
          />
        </FormTab>
      )}

      <FormTab label="Purchase">
        <PurchaseInputs
          isActive={hasPurchase}
          setIsActive={setHasPurchase}
          purchaseTerm="purchase"
          isEdit
        />
      </FormTab>

      {!isCertificate && (
        <FormTab label="Monthly subscriptions">
          <SubscriptionsInputs
            isActive={hasMonthlySubscriptions}
            setIsActive={setHasMonthlySubscriptions}
            subscriptionsTerm="monthlySubscriptions"
            isEdit
          />
        </FormTab>
      )}
      {!isCertificate && (
        <FormTab label="Yearly subscriptions">
          <SubscriptionsInputs
            isActive={hasYearlySubscriptions}
            setIsActive={setHasYearlySubscriptions}
            subscriptionsTerm="annualSubscriptions"
            isEdit
          />
        </FormTab>
      )}
    </TabbedForm>
  );
};

export default SuperAdminForm;
