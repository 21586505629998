import React, { FC } from 'react';
import { useRecordContext } from 'react-admin';
import { Fields } from './components';

type SubscriptionsFieldsProps = {
  subscriptionsTerm: string;
};

const PurchaseFields: FC<SubscriptionsFieldsProps> = ({
  subscriptionsTerm,
}) => {
  const context = useRecordContext();
  const isPrices = !!context.prices?.purchase;

  // console.log('CONT => ', context);

  return isPrices ? (
    <>
      <h3>Bronze</h3>
      <Fields subscriptionsTerm={subscriptionsTerm} type="bronze" />
      <h3>Silver</h3>
      <Fields subscriptionsTerm={subscriptionsTerm} type="silver" />
      <h3>Gold</h3>
      <Fields subscriptionsTerm={subscriptionsTerm} type="gold" />
    </>
  ) : (
    <p>Course does not have this subscription type</p>
  );
};

export default PurchaseFields;

// const Container = styled(SimpleShowLayout)`
//   display: flex;
//   flex-direction: column;
//   padding: 0 !important;
//   gap: 16px;
// `;
